import Layout from '../layout/index.vue'
export const datebaseRouter = {
    path: '/datebase',
    name: 'Datebase',
    // meta: {title: '组件管理'},
    component: Layout,
    children: [
        {
            path: 'datelist',
            name: 'Datelist',
            component: () => import('../views/datebase/datelist.vue')
          },
          {
            path: 'importdate',
            name: 'Importdate',
            component: () => import('../views/datebase/importdate.vue')
          },
          {
            path: 'indexinfo',
            name: 'Indexinfo',
            component: () => import('../views/datebase/indexinfo.vue')
          }
    ]
}