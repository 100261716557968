<template>
  <div id="app">
    <!-- <img class="nonet" v-if="!network" src="../public/network.png" /> -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      network: true,
    }
  },
  mounted() {
    // vm.$on('exportPDF', () => {
    //   window.print();
    // })
    // window.addEventListener('offline', () => {
    //   this.network = false
    // })
    // window.addEventListener('online', () => {
    //   // this.$message.error('网络异常，请检查网络状态！');
    //   this.network = true
    // })
  },
}
</script>
<style lang="scss">
#app {
  background: #F5F7FA;
  width: 100vw;
  min-width: 1590px;
}

// .nonet {
//   position: fixed !important;
//   top: 45%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   max-height: calc(100% - 30px);
//   max-width: calc(100% - 30px);
// }
</style>
