<template>
    <el-container class="h-full">
        <Aside></Aside>
        <el-container class="flex-col">
            <Header></Header>
            <el-main class="p-[10px]">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
  
<script>
import Aside from "./Aside.vue";
import Header from "./Header.vue";
export default {
    name: "Layout",
    data() {
        return {
        };
    },
    mounted() {
       
    },
    methods: {},
    components: {
        Aside,
        Header,
    },
};
</script>
  
<style scoped lang="scss">
.el-header {
    background-color: #b3c0d1;
    color: #333;
    line-height: 60px;
    margin-left: 10px !important;
}
// .el-aside {
//     margin-left: 10px;
// }

.el-menu {
    border: none;
}
</style>
  
  