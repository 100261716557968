import Layout from '../layout/index.vue'
export const systemRouter = {
    path: '/system',
    name: 'System',
    // meta: {title: '组件管理'},
    component: Layout,
    children: [
        {
            path: 'account',
            name: 'Account',
            component: () => import('../views/system/account.vue')
          },
          {
            path: 'entrylog',
            name: 'Entrylog',
            component: () => import('../views/system/entrylog.vue')
          },
          {
            path: 'operation',
            name: 'Operation',
            component: () => import('../views/system/operation.vue')
          },
    ]
}