import Layout from '../layout/index.vue'
export const permissionRouter = {
    path: '/permission',
    name: 'Permission',
    // meta: {title: '组件管理'},
    component: Layout,
    children: [
        {
            path: 'member',
            name: 'Member',
            component: () => import('../views/permission/member.vue')
          },
          {
            path: 'role',
            name: 'Role',
            component: () => import('../views/permission/role.vue')
          },
    ]
}