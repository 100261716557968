import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isOpen: false,
    isShow: false,
    menuList:[]
  },
  getters: {
  },
  mutations: {
    UPDATE_ISOPEN (state, data) {
      state.isOpen = data
    },
    UPDATE_ISSHOW (state, data) {
      // console.log(data)
      state.isShow = data
    },
    SET_MENU_LIST (state, data) {
      state.menuList = data
    }
  },
  actions: {
  },
  modules: {
  }
})
