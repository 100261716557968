import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'windi.css'
import '@/styles/index.scss'
import './assets/icons/index';
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import * as echarts from 'echarts';
import VueApexCharts from 'vue-apexcharts'
import "vue-easytable/libs/theme-default/index.css";
import { VeTable } from "vue-easytable";
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// import htmlToPdf from './utils/htmlToPdf.js'


Vue.prototype.$echarts = echarts;
Vue.prototype.$FileSaver = FileSaver;
Vue.prototype.$XLSX = XLSX;
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueApexCharts)
Vue.use(VeTable);
Vue.use(VXETable)
// Vue.use(htmlToPdf)
Vue.component('apexchart', VueApexCharts)

const app = new Vue({
  router,
  store,
  render: h => h(App)
})
global.vm = app
app.$mount('#app')