import Vue from 'vue'
import VueRouter from 'vue-router'
import { permissionRouter } from './permission.js'
import { systemRouter } from './system.js'
import { datebaseRouter } from './datebase.js'
import Cookie from 'js-cookie'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const menuListJSON = localStorage.getItem('menuList');
const menuList = menuListJSON ? JSON.parse(menuListJSON) : [];
const cookie1 = Cookie.get('token')
var redirctmenu = '';
// console.log(menuList)
// console.log(menuList == 0)
if (menuList.length == 0 || cookie1 == undefined) {
  redirctmenu = 'Login'
} else {
  if (!menuList[0].children) {
    redirctmenu = menuList[0].name
  } else if (menuList[0].children) {
    redirctmenu = menuList[0].children[0].name
  }
}
// console.log(redirctmenu)
const routes = [
  {
    path: '/',
    redirect: { name: redirctmenu },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/layout',
    name: 'Layout',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: 'revenue',
        name: 'Revenue',
        component: () => import('../views/revenue/index.vue'),
        meta: { title: '营收业绩分析' }
      },
      {
        path: 'marketing',
        name: 'Marketing',
        component: () => import('../views/marketing/index.vue'),
        meta: { title: '营销获利分析' }
      },
      {
        path: 'useranalysis',
        name: 'Useranalysis',
        component: () => import('../views/useranalysis/index.vue'),
        meta: { title: '用户分析' }
      },
      {
        path: 'salesconversion',
        name: 'Salesconversion',
        component: () => import('../views/salesconversion/index.vue'),
        meta: { title: '销售转化分析' }
      },
    ]
  },
  datebaseRouter,
  permissionRouter,
  systemRouter,
]

const router = new VueRouter({
  routes
})

const whiteList = ['Login'];
// const cookie = Cookie.get('token')
// console.log(cookie)
router.beforeEach((to, from, next) => {
  // next()
  const cookie = Cookie.get('token')
  const menuListJSON = localStorage.getItem('menuList');
  const menuList = menuListJSON ? JSON.parse(menuListJSON) : [];
  // console.log(cookie)//false
  if (cookie) {
    // console.log('9999999999')
    if (to.name == 'Login') {
      // console.log(111111111111)
      next(from.fullPath) // 去登录回到原来的路由
    } else {
      // console.log(222222222222222)
      const foundMenuItem = findMenuItem(menuList, to.name);
      if (foundMenuItem) {
        // console.log('跳转到目标路由')
        next() // 跳转到目标路由
      } else {
        // console.log('菜单项未找到，跳转到首页或其他默认页面')
        next(from.fullPath) // 菜单项未找到，跳转到首页或其他默认页面
      }
    }
  } else {//没有cookie
    // console.log(!whiteList.includes(to.name))
    if (!whiteList.includes(to.name)) {//不去登录
      // console.log(7777777777777)
      next('/login')
    } else {
      // console.log(6666666666666)
      next() // 白名单内的页面，允许访问
    }
  }
});

// 辅助函数：递归查找菜单项
function findMenuItem(menuList, targetName) {
  for (const menuItem of menuList) {
    if (menuItem.name == targetName) {
      // console.log('aaaaaaaaaaaaaaa')
      return true;
    } else if (menuItem.children && menuItem.children.length > 0) {
      if (findMenuItem(menuItem.children, targetName)) {
        return true;
      }
    }
  }
  return false;
}

export default router
