<template>
    <div class="year-range-picker">
        <el-date-picker v-model="startYear" type="year" placeholder="选择开始年" size="mini" class="year-picker"
            @change="changeStartYear" format="yyyy 年" value-format="yyyy">
        </el-date-picker>
        <span class="range-word text-[12px]"> : </span>
        <el-date-picker v-model="endYear" type="year" placeholder="选择结束年" size="mini" class="year-picker"
            @change="changeEndYear" format="yyyy 年" value-format="yyyy">
        </el-date-picker>
    </div>
</template>
  
  
<script>
export default {
    name: "YearRangePicker",
    // 接收父组件传入的数据
    props: {
        yearRange: Array
    },
    data() {
        return {
            startYear: "",
            endYear: "",
        };
    },
    mounted() {
        // // 初始化自身变量
        // this.startYear = String(this.yearRange[0]);
        // this.endYear = String(this.yearRange[1]);
    },
    methods: {
        pickYear() {
            this.startYear = '',
                this.endYear = ''
        },
        changeStartYear(val) {
            // 开始年份大于结束年份，调换
            // if (Number(this.startYear) > Number(this.endYear)) {
            //     this.startYear = this.endYear;
            //     this.endYear = val;
            // }
            // 将改动传回父组件
            this.$emit("yearChanged", [Number(this.startYear), Number(this.endYear)]);
        },
        changeEndYear(val) {
            if (Number(this.startYear) > Number(this.endYear)) {
                this.endYear = this.startYear;
                this.startYear = val;
            }
            this.$emit("yearChanged", [Number(this.startYear), Number(this.endYear)])
        },
    },
};
</script>
  
  
<style scoped>

.year-range-picker {
    color: black;
    text-align: center;
    font-size: 18px;
}

.range-word {
    margin-left: 10px;
    margin-right: 10px;
}

.year-range-picker .year-picker {
    max-width: 160px;
}

</style>
  