//axios二次封装
import Cookie from 'js-cookie';
import axios from "axios";
import { Message } from "element-ui"
//qs 第三方库 进行格式转换
import qs from 'qs';

//http://localhost:8081 开发环境接口地址
//http://localhost:8082 测试环境地址
//http://localhost:8083 生产环境地址

//创建实例
const api = axios.create({
    baseURL: 'https://api.dgart.club', //process.env.BASE_URL
    // timeout: 10000,
})


// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.timeout = 3000;


//axios 请求拦截器
api.interceptors.request.use(config => {
    //获取当前的token ，加到请求头中
    var token = Cookie.get("token")
    if (token) {
        config.headers['token'] = token
    }
    // console.log(token);

    // console.log(process.env.VUE_APP_BASE_URL);
    // console.log("-------请求拦截器-------");
    // config.url = config.baseURL;
    // console.log(config.data.params.pwd);
    // console.log(config);
    //获取用户验证信息
    // localStorage.getItem("user1");
    return config;
}, error => {
    return Promise.reject(error);
})

//axios 响应拦截器
api.interceptors.response.use(response => {
    // console.log("-------响应拦截器-------");
    // console.log(response)
    // console.log(localStorage.getItem('userinfo'))
    // if(response.status === 200) {}
    return response
}, error => {
    let { response } = error;
    if (response) {
        // console.log(response.data.code == 401);
        if (response.data.code == 401) {
            Cookie.remove('token')
            localStorage.removeItem('userinfo')
            localStorage.removeItem('role')
            localStorage.removeItem('menuList')
            localStorage.removeItem('rolemenu')
            localStorage.removeItem('roleid')
            window.location.reload()
            // window.location.href = '/login';
            return
        }
        return response.data.message
    } else {
        Message.error('数据请求失败，请检查网络连接或稍后重试');
        // return
        Promise.reject(error);
    }

})

export default api;