<template>
    <div class="headers">
        <el-header class="transition" :height="toggleDropdown ? '110px' : '60px'">
            <!-- <div v-if="toggleDropdown" class="border-bottom"></div> -->
            <div class="float-left text-[24px] h-[60px] leading-[60px]">
                <i class="cursor-pointer" :class="$store.state.isOpen ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
                    @click="handlerOpen"></i>
            </div>
            <div class="float-right flex items-center ml-auto mt-[10px]">
                <div v-if="isBtn" class="flex items-center ml-auto mt-[5px]">
                    <div class="flex items-center changeColor">
                        <svg class="svg-icon w-[18px] h-[18px]" aria-hidden="true">
                            <use href="#icon-u284" />
                        </svg>
                        <p class="text-[13px] mr-[10px]" @click="toggleDropdown = !toggleDropdown">筛选栏
                            <i v-if="!toggleDropdown" class="el-icon-arrow-down" size="40px"></i>
                            <i v-else class="el-icon-arrow-up" size="40px"></i>
                        </p>
                    </div>
                    <p class="mr-[10px]">
                        <el-dropdown @command="exportToPDF">
                            <span class="el-dropdown-link">
                                <i class="el-icon-more black-icon"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>导出为PDF</el-dropdown-item>
                                <!-- <el-dropdown-item>导出为图片</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                    </p>
                </div>

                <img class="w-[30px] h-[30px] mr-[5px] rounded-1/2" :src="headerpic" alt="" />
                <el-dropdown trigger="click" class="mr-[10px] flex fundown">
                    <span class="el-dropdown-link flex justify-center items-center">
                        <p class="titles text-[#000000]">{{ nickname }}</p>
                        <i class="el-icon-caret-bottom text-[black]"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="goinfo">个人信息</el-dropdown-item>
                        <el-dropdown-item @click.native="loginout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div v-if="toggleDropdown" class="w-[full] h-[40px] search pt-[65px]">
                <div class="search bb flex flex-wrap bg-[#EFEFEF] justify-start items-center pt-[3px]">
                    <div class="w-[300px] flex items-center flex-shrink-0 mr-[10px] mb-[5px] flex-row">
                        <p class="text-[14px]">时间维度：</p>
                        <el-select v-model="value" placeholder="请选择" size="mini" :class="{ 'apply-shake': shake }"
                            @change="datePickerTypechange">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <p class="text-[14px]">筛选时间：</p>
                    <div v-if="isShows" class="flex items-center flex-shrink-0" @click="shakeAnimation">
                        <el-date-picker v-model="timeFrom" @change="getTimes" :readonly="onlyRead" placeholder="请先选择时间维度"
                            :type="datePickerType" :key="datePickerType" range-separator=":" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']" size="mini"></el-date-picker>
                    </div>
                    <Query v-show="isShowquery" ref="childComponent" @queryChanged="queryChanged" class="mb-[5px]"></Query>
                    <year v-show="isShowyear" ref="childComponentyear" @yearChanged="yearChanged" class="mb-[5px]"></year>
                    <p class="mr-[10px] ml-[10px] mb-[5px]">
                        <el-button type="primary" class="bg-[#7A40F2] hover:bg-[#7A40F2] search-btn" icon="el-icon-search"
                            size="mini" @click="getUsersList">搜索</el-button>
                        <el-button type="primary" icon="el-icon-refresh-left"
                            class="bg-[#7A40F2] reset-btn hover:bg-[#7A40F2]" size="mini"
                            @click="resetUserList">重置</el-button>
                    </p>
                </div>
            </div>
        </el-header>
    </div>
</template>
  
<script>
import Query from "@/components/Query.vue"
import year from "@/components/year.vue"
import { logout, Usergrowth } from "@/api/index.js"
import Cookie from 'js-cookie';
export default {
    name: "Header",
    data() {
        return {
            shake: false,
            onlyRead: false,
            isShowquery: false,
            isShowyear: false,
            isShows: true,
            isBtn: false,
            timeFrom: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
            titles1: "",
            titles2: "",
            bol: true,
            isShow: true,
            headerpic: "",
            nickname: "",
            tokens: "",
            toggleDropdown: false,
            hasBottomBorder: false,
            value: "date", // Default value for el-select (can be changed as per your requirement)
            searchParams: {
                startTime: '',
                endTime: '',
                typetime: '日',
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            options: [
                { label: "日", value: "date" },
                { label: "月", value: "month", },
                { label: "季度", value: "quarter" },
                { label: "年", value: "year" },
            ],
            queryDate: [],
            yearDate: [],
        };
    },
    computed: {
        datePickerType() {
            if (this.value === "date") return "daterange";
            if (this.value === "month") return "monthrange";
            if (this.value === "quarter") {
                this.isShows = false;
                this.isShowquery = true
                this.isShowyear = false;
            };
            // console.log(this.value === "year")
            if (this.value === "year") {
                this.isShowyear = true
                this.isShowquery = false;
                this.isShows = false;
            };
        },
    },
    created() {
        const currentPath = this.$route.path;
        this.checkRoutePath(currentPath);
        // console.log(currentPath)
        // 创建当前日期对象
        const currentDate = new Date();
        // 设置 startTime 为当月一号的00:00:00
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
        this.searchParams.startTime = this.formatDate(firstDayOfMonth);
        // 设置 endTime 为今天日期的23:59:59
        const endOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
        this.searchParams.endTime = this.formatDate(endOfDay);
        // console.log(this.searchParams.startTime, this.searchParams.endTime)
    },
    watch: {
        value(newVal) {
            this.timeFrom = null;
        },
        $route(to, from) {
            this.checkRoutePath(to.path);
        },
    },
    mounted() {
        // console.log(this.$route)
        vm.$on('refresh', (olduserinfo) => {
            // console.log(olduserinfo)
            this.headerpic = olduserinfo.avatar;
            this.nickname = olduserinfo.nickname
        })
        this.tokens = Cookie.get('token')
        // console.log(this.tokens);
        this.headerpic = JSON.parse(localStorage.getItem("userinfo")).avatar;
        this.nickname = JSON.parse(localStorage.getItem("userinfo")).nickname
        this.titles1 = this.$route.matched[0].meta.title;
        this.titles2 =
            this.$route.matched[this.$route.matched.length - 1].meta.title;
    },
    methods: {
        shakeAnimation() {
            // console.log(this.value)
            if (this.value == '') {
                this.shake = true;
                setTimeout(() => {
                    this.shake = false;
                }, 820);
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        callChildFunctionQuery() {
            const child = this.$refs.childComponent;
            child.pickQuery();
        },
        callChildFunctionYear() {
            const child = this.$refs.childComponentyear
            child.pickYear();
        },
        queryChanged(queryVal) {
            // console.log(queryVal);
            function getQuarterDates(year, quarter) {
                let startDate, endDate;
                if (quarter === '第一季度') {
                    startDate = new Date(year, 0, 1);
                    endDate = new Date(year, 2, 31, 23, 59, 59);
                } else if (quarter === '第二季度') {
                    startDate = new Date(year, 3, 1);
                    endDate = new Date(year, 5, 30, 23, 59, 59);
                } else if (quarter === '第三季度') {
                    startDate = new Date(year, 6, 1);
                    endDate = new Date(year, 8, 30, 23, 59, 59);
                } else if (quarter === '第四季度') {
                    startDate = new Date(year, 9, 1);
                    endDate = new Date(year, 11, 31, 23, 59, 59);
                } else {
                    throw new Error('Invalid quarter. Valid values are 第一季度, 第二季度, 第三季度, 第四季度.');
                }
                return {
                    startDate,
                    endDate
                };
            }
            function formatDateRange(start, end) {
                const formattedStart = formatDate(start);
                const formattedEnd = formatDate(end);
                return `${formattedStart} ${formattedEnd}`;
            }
            function formatDate(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }
            const userSelections = queryVal;
            this.queryDate = []
            userSelections.forEach(selection => {
                const year = parseInt(selection.split('-')[0], 10);
                const quarter = selection.split('-')[1];
                const startDate = getQuarterDates(year, quarter).startDate;
                const endDate = getQuarterDates(year, quarter).endDate;
                const formattedDateRange = formatDateRange(startDate, endDate);
                // console.log(formattedDateRange);
                this.queryDate.push(formattedDateRange);
                const startDateString = this.queryDate[0].substring(0, 19);
                // console.log(startDateString);
                // console.log(this.queryDate, this.queryDate[1])
                this.searchParams.startTime = startDateString
                if (this.queryDate[1]) {
                    const endDateString = this.queryDate[1].substring(20);
                    // console.log(this.queryDate[3].substring(20));
                    this.searchParams.endTime = endDateString
                }
                // console.log(this.searchParams)
            });
        },
        yearChanged(yearVal) {
            // console.log(yearVal)
            function startDateTime(year) {
                const defaultDateTime = '01-01 00:00:00'; // 默认日期时间部分
                return year + '-' + defaultDateTime;
            }
            const year = yearVal[0];
            const formattedDateTime = startDateTime(year);
            // console.log(formattedDateTime);
            this.searchParams.startTime = formattedDateTime;
            if (yearVal[1] != 0) {
                function endDateTime(year1) {
                    const defaultDateTime = '12-31 23:59:59'; // 默认日期时间部分
                    return year1 + '-' + defaultDateTime;
                }
                const year1 = yearVal[1];
                const formattedDateTime1 = endDateTime(year1);
                // console.log(formattedDateTime1);
                this.searchParams.endTime = formattedDateTime1
            }
            // console.log(this.searchParams);
        },
        checkRoutePath(path) {
            if (
                path === '/layout/revenue' ||
                path === '/layout/marketing' ||
                path === '/layout/useranalysis' ||
                path === '/layout/salesconversion'
            ) {
                this.isBtn = true;
            } else {
                this.isBtn = false;
                this.toggleDropdown = false;
            }
        },
        getTimes(item) {
            console.log(item)
            // console.log(this.value)
            // this.datePickerTypechange()

            const newVal = item
            if (this.value === 'month') {
                // console.log(newVal[1])
                const modifiedDateString = this.getLastDayOfMonth(newVal[1]);
                // console.log(modifiedDateString)
                this.searchParams.startTime = newVal[0]
                this.searchParams.endTime = modifiedDateString
            } else {
                this.searchParams.startTime = newVal[0]
                this.searchParams.endTime = newVal[1]
            }
        },
        getLastDayOfMonth(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = date.getMonth();
            // 获取下个月的第一天
            const nextMonthFirstDay = new Date(year, month + 1, 1);
            // 获取本月的最后一天
            const lastDay = new Date(nextMonthFirstDay.getTime() - 1);
            // 格式化为 'yyyy-MM-dd HH:mm:ss' 格式的字符串
            const formattedLastDay = `${year}-${(month + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')} ${date.toTimeString().split(' ')[0]}`;
            return formattedLastDay;
        },
        datePickerTypechange(item) {
            // console.log(item)
            // console.log(this.value)

            this.onlyRead = false;
            if (this.value === "date") {
                this.isShowquery = false
                this.isShowyear = false
                this.isShows = true
                this.searchParams.typetime = '日'
            }
            if (this.value === "month") {
                this.isShowquery = false
                this.isShowyear = false
                this.isShows = true
                this.searchParams.typetime = '月'
            }
            if (item === 'year') {
                this.isShowquery = false
                this.isShowyear = true
                this.searchParams.typetime = '年'
            }

            if (item === 'quarter') {
                this.isShowquery = true
                this.isShowyear = false
                this.searchParams.typetime = '季'
            }
            // this.isShows = !(item === 'quarter')
            // console.log(this.isShows);
        },
        select(val) {
            // console.log(val.showValue);
            const newVal = val.showValue?.split(' - ')
            this.searchParams.startTime = newVal[0]
            this.searchParams.endTime = newVal[1]
        },
        getMsgFormSon() {
            // console.log(1)
        },
        exportToPDF(item) {
            // console.log(123123123123)
            vm.$emit('exportPDF')
        },
        getUsersList() {
            // console.log(this.timeFrom)
            if (this.searchParams.startTime == '' && this.searchParams.endTime == '') {
                this.$message({
                    message: '时间不能为空',
                    type: 'warning'
                });
                return
            }

            const startDate = new Date(this.searchParams.startTime);
            const endDate = new Date(this.searchParams.endTime);
            const timeDifference = endDate - startDate;
            const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
            if (this.value == 'date') {
                if (daysDifference > 31) {
                    this.$message.error("日期范围不能超过31天。");
                    return;
                }
            } else if (this.value == 'month') {
                if (daysDifference > 365) {
                    this.$message.error("日期范围不能超过1年。");
                    return;
                }

            } else if (this.value == 'year') {
                if (daysDifference > 4380) {
                    this.$message.error("日期范围不能超过12年。");
                    return;
                }
            } else {
                if (daysDifference > 365) {
                    this.$message.error("日期范围不能超过1年。");
                    return;
                }
            }
            vm.$emit('search', this.searchParams)
        },
        resetUserList() {
            if (this.searchParams.startTime == '' && this.searchParams.endTime == '') {
                // this.$message({
                //     message: '时间不能为空',
                //     type: 'warning'
                // });
                // this.isTimes = true
                return
            }
            this.onlyRead = true
            // this.callChildFunctionQuery()
            const child = this.$refs.childComponent;
            child.pickQuery();
            // this.callChildFunctionYear()
            const child1 = this.$refs.childComponentyear
            child1.pickYear();
            this.searchParams.startTime = ''
            this.searchParams.endTime = ''
            this.searchParams.typetime = '',
                this.value = ''
            // if (this.value == '') {
            //     this.isTime = true
            // }
            this.timeFrom = '',
                // console.log(this.searchParams)
                vm.$emit('search', this.searchParams)
        },
        loginout() {
            logout().then(res => {
                // console.log(res.data)
                Cookie.remove('token')
                localStorage.removeItem('userinfo')
                localStorage.removeItem('role')
                localStorage.removeItem('menuList')
                localStorage.removeItem('rolemenu')
                localStorage.removeItem('roleid')
                const cookie = Cookie.get('token')
                // console.log(cookie)//true
                this.$message({
                    message: '您已退出登录！',
                    type: 'success'
                });
                if (!cookie) {
                    // console.log(78945120.1289789)
                    this.$router.push('/login')
                }

            })
        },
        goinfo() {
            this.$router.push('/system/account')
        },
        handleChange() {
            // console.log(1);
        },
        handlerOpen() {
            this.$store.commit("UPDATE_ISOPEN", !this.$store.state.isOpen);
        },
        handleScreen() {
            this.isShow = !this.isShow;
        },
    },
    components: {
        Query,
        year,
    },
};
</script>
  
<style scoped lang="scss">
@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.fundown {
    .el-dropdown-menu {
        position: absolute !important;
        top: 38px !important;
        left: 1781px !important;
    }
}

.el-dropdown-menu__item:hover {
    background: #7A40F2 !important;
    color: #fff !important;
}

.bb {
    width: 100%;
    border-top: 2px solid #DDE0E5;
}

.changeColor:hover {
    color: #F0B93C;
    cursor: pointer;

    .svg-icon use {
        fill: #F0B93C;
    }
}

.el-button {
    border: none !important;
}

.black-icon {
    color: black;
}

.search {
    background: white !important;
}

.el-header {
    width: 97.6%;
    margin-left: 15px !important;
    background: white !important;
    color: black !important;
    border-radius: 20px;
    position: relative;
    border-bottom: 5px solid #F5F7FA;
}

.titles {
    font-size: 14px;
    padding-top: 5px;
    margin-right: 5px;
}

.el-header {
    background: white !important;
    color: black !important;
    border-radius: 15px 15px 15px 15px;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409eff;
}

.el-icon-arrow-down {
    font-size: 12px;
}

// .el-popper {
//     margin-top: 50px;
// }

.transition {
    transition: all .3s linear !important;
}

.el-header.has-border {
    border-bottom: 1px solid #EFEFEF;
}

.border-bottom {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #EFEFEF;
}
</style>
  
  