<template>
  <div>
    <mark style="
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0);
        z-index: 999;
      " v-show="showSeason" @click.stop="showSeason = false"></mark>
    <el-input placeholder="请选择季度" v-model="showValue" style="width: 230px" @focus="focusClick" clearable size="mini">
      <i slot="prefix" class="el-input__icon el-icon-date"></i>
    </el-input>
    <el-card class="box-card" style="
        width: 500px;
        padding: 0 3px 20px;
        margin-top: 10px;
        position: fixed;
        z-index: 9999;
      " v-show="showSeason">
      <el-row>
        <el-col :span="12">
          <!-- <div class="left"> -->
          <div style="text-align: center; font-size: 14px; color: #606266">
            开始时间
          </div>
          <div class="clearfix" style="text-align: center; padding: 0; margin-top: 10px">
            <button type="button" aria-label="前一年"
              class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left" @click="prev"></button>
            <span role="button" class="el-date-picker__header-label">{{ year }}年</span>
            <button type="button" aria-label="后一年" @click="next"
              class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"></button>
          </div>
          <!-- 季度选择器 -->
          <div class="text item" style="text-align: center">
            <el-button type="text" size="medium" style="width: 40%; color: #606266; float: left" :class="[
              'common-quarter',
              'quarter-one',
              quarterActive === '一' ? 'is-active' : '',
            ]" @click="selectSeason('一')">第一季度</el-button>
            <el-button type="text" size="medium" style="float: right; width: 40%; color: #606266" :class="[
              'common-quarter',
              'quarter-tow',
              quarterActive === '二' ? 'is-active' : '',
            ]" @click="selectSeason('二')">第二季度</el-button>
          </div>
          <div class="text item" style="text-align: center">
            <el-button type="text" size="medium" style="width: 40%; color: #606266; float: left" :class="[
              'common-quarter',
              'quarter-three',
              quarterActive === '三' ? 'is-active' : '',
            ]" @click="selectSeason('三')">第三季度</el-button>
            <el-button type="text" size="medium" style="float: right; width: 40%; color: #606266" :class="[
              'common-quarter',
              'quarter-four',
              quarterActive === '四' ? 'is-active' : '',
            ]" @click="selectSeason('四')">第四季度</el-button>
            <!-- </div> -->
          </div>
        </el-col>
        <el-col :span="12">
          <!-- <div class="right"> -->
          <div style="text-align: center; font-size: 14px; color: #606266">
            结束时间
          </div>
          <div class="clearfix" style="text-align: center; padding: 0; margin-top: 10px">
            <button type="button" aria-label="前一年"
              class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left" @click="prev1"></button>
            <span role="button" class="el-date-picker__header-label">{{ year2 }}年</span>
            <button type="button" aria-label="后一年" @click="next1"
              class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"></button>
          </div>
          <div class="text item" style="text-align: center">
            <el-button type="text" size="medium" style="width: 40%; color: #606266; float: left; " :class="[
              'common-quarter',
              'quarter-one',
              quarterActive1 === '一' ? 'is-active1' : '',
            ]" @click="selectQuartern('一')">第一季度</el-button>
            <el-button type="text" size="medium" style="float: right; width: 40%; color: #606266; " :class="[
              'common-quarter',
              'quarter-tow',
              quarterActive1 === '二' ? 'is-active1' : '',
            ]" @click="selectQuartern('二')">第二季度</el-button>
          </div>
          <div class="text item" style="text-align: center">
            <el-button type="text" size="medium" style="width: 40%; color: #606266; float: left;" :class="[
              'common-quarter',
              'quarter-three',
              quarterActive1 === '三' ? 'is-active1' : '',
            ]" @click="selectQuartern('三')">第三季度</el-button>
            <el-button type="text" size="medium" style="float: right; width: 40%; color: #606266;" :class="[
              'common-quarter',
              'quarter-four',
              quarterActive1 === '四' ? 'is-active1' : '',
            ]" @click="selectQuartern('四')">第四季度</el-button>
          </div>
          <!-- </div> -->
        </el-col>
      </el-row>
      <el-button primary @click="pick" style="float: right">确定</el-button>
    </el-card>
  </div>
</template>
<script>
/**
 * @file:  View 组件 季节选择控件
 * @author: v_zhuchun
 * @description: UI组件  可选择季节
 * @api: valueArr : 季度value defalut['01-03', '04-06', '07-09', '10-12'] 默认值待设置
 */
export default {
  // props: {},
  data() {
    return {
      showSeason: false,
      year: '', //默认当前年
      year2: '',
      showValue: '', //页面上input的内容
      quarterActive: '',
      quarterActive1: '',
      value: '',
      value1: '',
    }
  },

  created() { },

  watch: {},

  methods: {
    // 上一年
    prev() {
      this.year = +this.year - 1
    },
    // 下一年
    next() {
      this.year = +this.year + 1
    },
    // 上一年
    prev1() {
      this.year2 = +this.year2 - 1
    },
    // 下一年
    next1() {
      this.year2 = +this.year2 + 1
    },
    // 季度选择
    selectSeason(quarter) {
      this.quarterActive = quarter
      // console.log(this.quarterActive)
      this.showSeason = true
      this.value = `${this.year}-第${quarter}季度`
    },
    selectQuartern(quarter) {
      this.quarterActive1 = quarter
      this.showSeason = true
      this.value1 = `${this.year2}-第${quarter}季度`
    },
    pick() {
      this.showSeason = false
      this.showValue = this.value + this.value1
      this.$emit("queryChanged", [this.value, this.value1])
      // console.log(this.value,'1234567890')
      // console.log(this.value1)
    },
    pickQuery() {
      // console.log('清空11111111111111111111111111111')
      this.showValue = ''
    },
    focusClick() {
      if (!this.year) {
        this.year = new Date().getFullYear().toString()
        this.year2 = new Date().getFullYear().toString()
      }
      this.showSeason = true
      this.value = ''
      this.value1 = ''
      this.quarterActive1 = ''
      this.quarterActive = ''
      this.year = new Date().getFullYear().toString()
      this.year2 = new Date().getFullYear().toString()
    },
  },
}
</script>
<style scoped>

.left {
  width: 240px;
  height: 100px;
}

.right {
  width: 240px;
  float: right;
  top: 0;
  height: 100px;
}

.is-active,
.is-active1 {
  color: #409eff !important;
  border: 1px solid #409eff;
}

</style>