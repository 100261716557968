<template>
    <el-aside class="bg-[#7A40F2] transition" :width="!this.$store.state.isOpen ? '200px' : '60px'">
        <div class="w-[100%] h-[50px] mt-[20px] mb-[20px] text-center text-[#fff] leading-[50px] cursor-pointer flex">
            <p class="mt-2 ml-3 mr-2">
                <img src="../assets//u18.svg" alt="" class="w-[40px] h-[40px] " />
            </p>
            <h1 class="h-[50px] leading-[50px] font-semibold text-[18px] overflow-hidden"
                v-show="!this.$store.state.isOpen">
                东馆数据看板
            </h1>
        </div>
        <el-menu text-color="#fff" router :default-active="defaultActive" :collapse="$store.state.isOpen"
            :collapse-transition="false" v-for="(item) in menuList" :unique-opened="true" :key="item.id">
            <el-menu-item v-if="!item.children" :index="'/layout/' + item.path">
                <svg class="svg-icon w-[14px] h-[14px]" aria-hidden="true">
                    <use :href="'#icon-' + item.icon" />
                </svg>
                <span class="px-3" slot="title">{{ item.title }}</span>
            </el-menu-item>
            <el-submenu v-else background-color="#7A40F2" class="submenu" :index="item.id">
                <template slot="title">
                    <svg class="svg-icon w-[14px] h-[14px]" aria-hidden="true">
                        <use :href="'#icon-' + item.icon" />
                    </svg>
                    <span class="px-3">{{ item.title }}</span>
                </template>
                <el-menu-item v-for="(a) in item.children" :key="a.id" :index="item.path + '/' + a.path">
                    <span class="px-3">{{ a.title }}</span>
                </el-menu-item>
            </el-submenu>
        </el-menu>
    </el-aside>
</template>
  
<script>
import { user } from '@/api/index.js'
export default {
    name: "Aside",
    data() {
        return {
            defaultActive: "/",
            menuList: '',
        };
    },
    watch: {
        $route(to) {
            // console.log(to);
            this.defaultActive = to.path;
        },
    },
    mounted() {
        this.defaultActive = this.$route.path;
        // console.log(this.defaultActive)
        this.menuList = JSON.parse(localStorage.getItem("menuList"));

    },
    methods: {},
    components: {},
};
</script>
  
<style scoped lang="scss">
.el-button {
    border: none !important;
}

.el-menu--vertical ul li {
    margin: 5px auto;
}

::v-deep .el-submenu__title i {
    color: black !important;
}

::v-deep .submenu .el-submenu__title {
    background: #7A40F2 !important;
    // color: #C9AFFE !important;
    font-weight: lighter !important;
}

.el-aside {
    background: #7A40F2 !important;
}

::v-deep .el-menu {
    background: #7A40F2 !important;
}

.el-menu-item {
    width: 80%;
    border-radius: 30px;
    height: 50px;
    line-height: 50px;
    background: #7A40F2 !important;
    // color: #C9AFFE !important;
    font-weight: lighter !important;
}

::v-deep .el-submenu {
    background: #7A40F2 !important;
}

.el-menu-item.is-active {
    background: #6630D4 !important;
    color: white !important;
    font-weight: bold !important;
}

.el-aside {
    border-radius: 0px 40px 40px 0;
}

.el-menu {
    border: none;
    background: transparent !important;
}

svg {
    fill: white;
}

.svg-icon {
    display: inline-block;
}

.transition {
    transition: all .3s ease-in !important;
}

::v-deep .el-submenu__title {
    height: 50px !important;
    line-height: 50px !important;
}
</style>
  
  