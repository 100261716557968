import api from "../http/request.js"
import Cookie from 'js-cookie';
import qs from "qs"

//  api/admin/login
export const gologin = (data) => api({
    url: 'api/admin/login', //请求地址
    method:'POST', //请求方式
    data
})

// api/admin/profile
export const profile = (data) => api({
    url: 'api/admin/profile', //请求地址
    method:'POST', //请求方式
    data
})

// api/admin/addAdminLog
export const addAdminLog = (data) => api({
    url: 'api/admin/addAdminLog', //请求地址
    method:'POST', //请求方式
    data
})

// api/admin/logout 
export const logout = (data) => api({
    url: 'api/admin/logout', //请求地址
    method:'POST', //请求方式
    data
})

// api/admin/loginlog
export const loginlog = (data) => api({
    url: 'api/admin/loginlog',
    method:'POST', //请求方式
    data
})

// api/admin/operation
export const operation = (data) => api({
    url: 'api/admin/operation', //请求地址
    method:'POST', //请求方式
    data
})

// api/admin/operationlog
export const operationlog = (data) => api({
    url: 'api/admin/operationlog', //请求地址
    method:'POST', //请求方式
    data
})

// index/ajax/upload
export const upload = (data) => api({
    url: 'api/admin/operationlog',
    method:'POST', //请求方式
    data
})

// api/admin/managers
export const managers = (data) => api({
    url: 'api/admin/managers',
    method:'POST', //请求方式
    data
})

// api/admin/deleteAdmin
export const deleteAdmin = (data) => api({
    url: 'api/admin/deleteAdmin',
    method:'POST', //请求方式
    data
})

// api/admin/register
export const register = (data) => api({
    url: 'api/admin/register',
    method:'POST', //请求方式
    data
})

// api/admin/edit
export const edit = (data) => api({
    url: 'api/admin/edit',
    method:'POST', //请求方式
    data
})

// index/ajax/role
export const role = (data) => api({
    url: 'api/admin/role',
    method:'POST', //请求方式
    data
})

// api/admin/exportUser
export const exportUser = (data) => api({
    url: 'api/admin/exportUser',
    method:'POST', //请求方式
    data
})

// addrole
export const addrole = (data) => api({
    url: 'api/admin/addrole',
    method:'POST', //请求方式
    data
})

// api/admin/editrole
export const editrole = (data) => api({
    url: 'api/admin/editrole',
    method:'POST', //请求方式
    data
})

export const ro = (data) => api({
    url: 'api/admin/ro',
    method:'POST', //请求方式
    data
})

// api/tanma/LearningUsers
export const LearningUsers = (data) => api({
    url: 'api/tanma/LearningUsers',
    method:'POST', //请求方式
    data
})

// api/tanma/PaymentRate
export const PaymentRate = (data) => api({
    url: 'api/tanma/PaymentRate',
    method:'POST', //请求方式
    data
})

// api/tanma/Usergrowth
export const Usergrowth = (data) => api({
    url: 'api/tanma/Usergrowth',
    method:'POST', //请求方式
    data
})

// api/admin/deleteRole
export const deleteRole = (data) => api({
    url: 'api/admin/deleteRole',
    method:'POST', //请求方式
    data
})

// api/tanma/paymentChannelProportion
export const paymentChannelProportion = (data) => api({
    url: 'api/tanma/paymentChannelProportion',
    method:'POST', //请求方式
    data
})

// api/tanma/paymentChannelProportionCareer
export const paymentChannelProportionCareer = (data) => api({
    url: 'api/tanma/paymentChannelProportionCareer',
    method:'POST', //请求方式
    data
})

// api/tanma/proportionCareer
export const proportionCareer = (data) => api({
    url: 'api/tanma/proportionCareer',
    method:'POST', //请求方式
    data
})

// api/tanma/TyproportionCareer
export const TyproportionCareer = (data) => api({
    url: 'api/tanma/TyproportionCareer',
    method:'POST', //请求方式
    data
})

// api/admin/user
export const user = (data) => api({
    url: 'api/admin/user',
    method:'POST', //请求方式
    data
})

// api/tanma/new_clue
export const new_clue = (data) => api({
    url: 'api/tanma/new_clue',
    method:'POST', //请求方式
    data
})

// api/tanma/new_consultations
export const new_consultations = (data) => api({
    url: 'api/tanma/new_consultations',
    method:'POST', //请求方式
    data
})

// api/tanma/new_employee
export const new_employee = (data) => api({
    url: 'api/tanma/new_employee',
    method:'POST', //请求方式
    data
})

// api/tanma/new_pay
export const new_pay = (data) => api({
    url: 'api/tanma/new_pay',
    method:'POST', //请求方式
    data
})

// api/tanma/new_lead_trend
export const new_lead_trend = (data) => api({
    url: 'api/tanma/new_lead_trend',
    method:'POST', //请求方式
    data
})

// api/admin/user_list
export const user_list = (data) => api({
    url: 'api/admin/user_list',
    method:'POST', //请求方式
    data
})

// api/tanma/get_conversion_data
export const get_conversion_data = (data) => api({
    url: 'api/tanma/get_conversion_data',
    method:'POST', //请求方式
    data
})

// api/tanma/subchannel
export const subchannel = (data) => api({
    url: 'api/tanma/subchannel',
    method:'POST', //请求方式
    data
})

// api/tanma/portrait
export const portrait = (data) => api({
    url: 'api/tanma/portrait',
    method:'POST', //请求方式
    data
})

// api/tanma/channel_conversion
export const channel_conversion = (data) => api({
    url: 'api/tanma/channel_conversion',
    method:'POST', //请求方式
    data
})

// api/tanma/list_of_achievements
export const list_of_achievements = (data) => api({
    url: 'api/tanma/list_of_achievements',
    method:'POST', //请求方式
    data
})

// index/ajax/upload
export const uploads = (data) => api({
    url: 'api/ajax/upload',
    method:'POST', //请求方式
    data
})

// api/tanma/list_of_achievements_importExcel
export const list_of_achievements_importExcel = (data) => api({
    url: 'api/tanma/list_of_achievements_importExcel',
    method:'POST', //请求方式
    data
})

// api/tanma/delete_performance
export const delete_performance = (data) => api({
    url: 'api/tanma/delete_performance',
    method:'POST', //请求方式
    data
})

// api/tanma/batchDeleteAchievements
export const batchDeleteAchievements = (data) => api({
    url: 'api/tanma/batchDeleteAchievements',
    method:'POST', //请求方式
    data
})

// api/tanma/getSalesData
export const getSalesData = (data) => api({
    url: 'api/tanma/getSalesData',
    method:'POST', //请求方式
    data
})

// api/tanma/commenttype
export const commenttype = (data) => api({
    url: 'api/tanma/commenttype',
    method:'POST', //请求方式
    data
})

// api/tanma/getPerformanceData
export const getPerformanceData = (data) => api({
    url: 'api/tanma/getPerformanceData',
    method:'POST', //请求方式
    data
})

// api/tanma/getChannelPerformance
export const getChannelPerformance = (data) => api({
    url: 'api/tanma/getChannelPerformance',
    method:'POST', //请求方式
    data
})

// api/tanma/getPerformance
export const getPerformance = (data) => api({
    url: 'api/tanma/getPerformance',
    method:'POST', //请求方式
    data
})

// api/tanma/commenttype_trend
export const commenttype_trend = (data) => api({
    url: 'api/tanma/commenttype_trend',
    method:'POST', //请求方式
    data
})

// api/tanma/saveMetrics
export const saveMetrics = (data) => api({
    url: 'api/tanma/saveMetrics',
    method:'POST', //请求方式
    data
})

// api/tanma/getMetrics
export const getMetrics = (data) => api({
    url: 'api/tanma/getMetrics',
    method:'POST', //请求方式
    data
})

// api/tanma/getTotalSales
export const getTotalSales = (data) => api({
    url: 'api/tanma/getTotalSales',
    method:'POST', //请求方式
    data
})

// api/tanma/getTotalSales_month
export const getTotalSales_month = (data) => api({
    url: 'api/tanma/getTotalSales_month',
    method:'POST', //请求方式
    data
})

// api/tanma/new_getTotal_month
export const new_getTotal_month = (data) => api({
    url: 'api/tanma/new_getTotal_month',
    method:'POST', //请求方式
    data
})

// api/tanma/purchase_getTotal_month
export const purchase_getTotal_month = (data) => api({
    url: 'api/tanma/purchase_getTotal_month',
    method:'POST', //请求方式
    data
})

// api/tanma/refund_getTotal_month
export const refund_getTotal_month = (data) => api({
    url: 'api/tanma/refund_getTotal_month',
    method:'POST', //请求方式
    data
})

// api/tanma/channel_conversion_trend
export const channel_conversion_trend = (data) => api({
    url: 'api/tanma/channel_conversion_trend',
    method:'POST', //请求方式
    data
})

// api/tanma/getConversionRates
export const getConversionRates = (data) => api({
    url: 'api/tanma/getConversionRates',
    method:'POST', //请求方式
    data
})

// api/tanma/new_clue
export const new_clueSale = (data) => api({
    url: 'api/tanma/new_clue',
    method:'POST', //请求方式
    data,
})

// api/tanma/new_clue
export const new_clueSaleblob = (data) => api({
    url: 'api/tanma/new_clue',
    method:'POST', //请求方式
    data,
    responseType: 'blob'
})

// api/tanma/monthly_experience
export const monthly_experience = (data) => api({
    url: 'api/tanma/monthly_experience',
    method:'POST', //请求方式
    data
})

// api/tanma/play_period_retention
export const play_period_retention = (data) => api({
    url: 'api/tanma/play_period_retention',
    method:'POST', //请求方式
    data
})

// api/tanma/monthly_payment
export const monthly_payment = (data) => api({
    url: 'api/tanma/monthly_payment',
    method:'POST', //请求方式
    data
})

// api/tanma/payment_conversion_days
export const payment_conversion_days = (data) => api({
    url: 'api/tanma/payment_conversion_days',
    method:'POST', //请求方式
    data
})

// api/tanma/Usergrowth
export const UsergrowthSale = (data) => api({
    url: 'api/tanma/Usergrowth',
    method:'POST', //请求方式
    data
})

// api/tanma/channel_conversion
export const channel_conversionSale = (data) => api({
    url: 'api/tanma/channel_conversion',
    method:'POST', //请求方式
    data
})

// api/tanma/conversion_trend
export const conversion_trend = (data) => api({
    url: 'api/tanma/conversion_trend',
    method:'POST', //请求方式
    data
})

// api/tanma/conversion_trend
export const conversion_trendLoop = (data) => api({
    url: 'api/tanma/conversion_trend',
    method:'POST', //请求方式
    data
})

// api/tanma/user_data_details
export const user_data_details = (data) => api({
    url: 'api/tanma/user_data_details',
    method:'POST', //请求方式
    data
})

// api/tanma/customer_service_conversion
export const customer_service_conversion1 = (data) => api({
    url: 'api/tanma/customer_service_conversion',
    method:'POST', //请求方式
    data
})

// api/tanma/customer_service_conversion
export const customer_service_conversion2 = (data) => api({
    url: 'api/tanma/customer_service_conversion',
    method:'POST', //请求方式
    data
})

// api/tanma/calculateARPU
export const calculateARPU = (data) => api({
    url: 'api/tanma/calculateARPU',
    method:'POST', //请求方式
    data
})

// api/admin/exporting_logs
export const exporting_logs = (data) => api({
    url: 'api/admin/exporting_logs',
    method:'POST', //请求方式
    data
})